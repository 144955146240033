import React from 'react';
import logo from './img/logo.svg';
import line from './img/line.svg';
import eating from './img/eating.jpg';
import cat from './img/cat.jpg';
import turtle from './img/turtle3.jpg';
import './App.css';
import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RecyclingIcon from '@mui/icons-material/Recycling';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import ScienceIcon from '@mui/icons-material/Science';
import PetsIcon from '@mui/icons-material/Pets';

function App() {

  const [showPhone, setShowPhone] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);

  return (
    <Container className="Container" maxWidth="md">
      <Stack className="App" spacing={2} py={2}>
        <img src={logo} className="App-logo" alt="logo" />
        <Card variant="outlined">
          <CardHeader
            avatar={<Avatar src={eating} aria-label="李羿廷" />}
            title="李羿廷 Eating"
            subheader="負責人"
          />
          <CardMedia
            component="img"
            image={cat}
            alt="李羿廷 EATING"
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              哈囉！我是年資十年的軟體與機電工程師，目前正在食品所進修，研究冷凍乾燥用於食品加工的課題。
            </Typography>
            <Typography variant="body1" color="text.secondary">
              我家的貓咪：大貓（玳瑁）、小棕（橘貓）、阿花（白貓）、叉燒（白貓）
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="line" size="large" href="https://line.me/ti/p/z3nvTeoPh0" target="_blank">
              <img src={line} alt="line" width={25} />
            </IconButton>
            <IconButton aria-label="email" size="large" color="primary" onClick={() => { setShowEmail(true) }}>
              <EmailIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="phone" size="large" color="primary" onClick={() => { setShowPhone(true) }}>
              <PhoneIphoneIcon fontSize="inherit" />
            </IconButton>
          </CardActions>
        </Card>

        <Card variant="outlined">
          <CardHeader
            avatar={<Avatar aria-label="海瑰生機">S</Avatar>}
            title="SEA FUSION"
            subheader="海瑰生機"
          />
          <CardMedia
            component="img"
            image={turtle}
            alt="海龜"
          />
          <CardContent>
            <List dense={false}>
              <ListItem>
                <ListItemIcon><RecyclingIcon color="success" /></ListItemIcon>
                <ListItemText primary="環境資源-再用製程研發" />
              </ListItem>
              <ListItem>
                <ListItemIcon><LocalFloristIcon color="success" /></ListItemIcon>
                <ListItemText primary="海洋萃取-膠原蛋白凍乾" />
              </ListItem>
              <ListItem>
                <ListItemIcon><SettingsIcon color="success" /></ListItemIcon>
                <ListItemText primary="冷凍乾燥-設備規劃訂製" />
              </ListItem>
              <ListItem>
                <ListItemIcon><ScienceIcon color="success" /></ListItemIcon>
                <ListItemText primary="食品安全-產品檢驗分析" />
              </ListItem>
              <ListItem>
                <ListItemIcon><PetsIcon color="success" /></ListItemIcon>
                <ListItemText primary="CATION-寵物凍乾食品" />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Stack>

      <Dialog
        open={showEmail}
        onClose={() => { setShowEmail(false) }}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          聯絡信箱
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            eatingli0622@gmail.com
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setShowEmail(false) }}>關閉</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPhone}
        onClose={() => { setShowPhone(false) }}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          連絡電話
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <a href="tel:+886927060008">+886 927-060-008</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setShowPhone(false) }}>關閉</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default App;
